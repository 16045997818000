<template>
  <div>
    <top-navigation
        :date="date"
        :summary="summary"
        :working-times="workingTimes"
        :notices="notices"
        :memo="memo"
        :work-start="workStart"
        :work-end="workEnd"
        :time-table-start="timeTableStart"
        :show-modal-reservation-block="showReservationModal"
        :show-modal-reservation-course="showModalReservationCourse"
        :time-table-end="timeTableEnd"
        :memo-list="memoList"
        @change-date="changeDate"
        @reload="reload"
        @reload-memo="reloadMemo"
        @change-print-time="changePrintTime"
        @confirm-notice="confirmNotice"
        @save-memo="saveMemo"
        @change-modal-reservation-course="changeModalReservationCourse"
        @change-modal-reservation="changeModalReservation"
    ></top-navigation>

    <reservation-exist-outside-business-hour-label
        :date="date"
        @change-date="changeDate">
    </reservation-exist-outside-business-hour-label>

    <time-table
        :date="date"
        :loading="loading"
        :working-times="workingTimes"
        :work-start="workStart"
        :work-end="workEnd"
        :time-table-start="timeTableStart"
        :time-table-end="timeTableEnd"
        :time-table="timeTable"
        :notices="notices"
        :print-time-start="printTimeStart"
        :print-time-end="printTimeEnd"
        @change-date="changeDate"
        @loading="loading = $event"
        @reload="reload"
    ></time-table>

    <ReservationList v-if="displayReservationList" :displayReservationList="displayReservationList" :selectedDate="selectedDate" :startTime="startTime" :endTime="endTime" @update:displayReservationList="displayReservationList = false"/>

  </div>

</template>

<script>
import Vue from "vue/dist/vue.esm";
import {decode_data, get_url_parameter, sleep} from "../../shared/util";
import TopNavigation from "./components/TopNavigation";
import TimeTable from "./components/TimeTable.vue";
import ReservationExistOutsideBusinessHourLabel from "./components/ReservationExistOutsideBusinessHourLabel.vue";
import moment from "moment";
import Http from "../../shared/http";
import DateComponent from "./components/TopNavDateComponent.vue";
import ReservationTime from "./components/ReservationTime.vue";
import PrintModalForm from "./components/PrintModalForm.vue";
import ReservationList from "../components/ReservationList.vue";

export default {
  name: "TimeTableParent",
  components: {TopNavigation, TimeTable, ReservationExistOutsideBusinessHourLabel,PrintModalForm, ReservationList},
  data() {
    return {
      shop: decode_data("#data-shop"),
      date: new Date(get_url_parameter("date") || new Date()),
      timeTable: [],
      summary: {},
      workingTimes: [],
      notices: [],
      memo: {},
      loading: false,
      printTimeStart: null,
      printTimeEnd: null,
      printError: null,
      reservationComponentInstance: null,
      summaryComponent: null,
      dateComponentInstance:null,
      printComponentInstance: null,
      showReservationModal: false,
      showModalReservationCourse: false,
      printPressTime: null,
      memoList: [],
      displayReservationList: false,
      startTime:null,
      endTime:null,
      selectedDate:null
    };
  },
  computed: {
    workStart() {
      const workStart = this.workingTimes[0]?.start_time
      return moment(workStart).set({minute: 0, second: 0, millisecond: 0})
    },
    workEnd() {
      const workEnd = this.workingTimes[this.workingTimes.length - 1]?.end_time
      const end = moment(workEnd).set({minute: 0, second: 0, millisecond: 0})
      if (moment(workEnd).minute() > 0) end.add(1, 'hour')
      return end
    },
    timeTableStart() {
      if (this.printTimeStart) return moment(this.printTimeStart)
      const reservations = this.timeTable.map(t => t.reservations).flat()
      let minReservationStart = reservations.reduce((min, r) => min < r.start ? min : r.start, null)
      minReservationStart = minReservationStart ? moment(minReservationStart) : this.workStart
      return moment.min([this.workStart, minReservationStart])
    },
    timeTableEnd() {
      if (this.printTimeEnd) return moment(this.printTimeEnd)
      const reservations = this.timeTable.map(t => t.reservations).flat()
      let maxReservationEnd = reservations.reduce((max, r) => max > r.end ? max : r.end, null)
      maxReservationEnd = maxReservationEnd ? moment(maxReservationEnd) : this.workEnd
      return moment.max([this.workEnd, maxReservationEnd])
    },
  },
  watch: {
    workingTimes() {
      this.remountReservationTimeComponent();
    },
    summary() {
      this.remountReservationSummaryComponent()
    },
    printError() {

      this.remountReservationSummaryComponent();
    },
  },
  async mounted() {
    await this.getTimeTableData(this.date);
    this.getNotices(this.date);
    this.getMemo(this.date);
    this.mountReservationTimeComponent()
    this.mountReservationSummaryComponent()
    this.mountPrintScreen()
  },
  methods: {
    reloadMemo(date) {
      this.getMemo(this.date);
    },
    reload(silent = false) {
      this.getTimeTableData(this.date, silent);
      this.$root.$emit('check-reservation', this.date);
    },
    async changeDate(date) {

      this.getTimeTableData(date);
      this.getNotices(date);
      this.getMemo(date);
    },
    changePrintTime({start, end}) {
      this.printTimeStart = start
      this.printTimeEnd = end
    },
    async getTimeTableData(date, silent = false) {
      if (!silent) this.loading = true;
      const date_param = moment(date).format("YYYY-MM-DD");
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;

      await Http.get(`${slug}/shop_manager/time_table/time_table_data`, {
        type: "day",
        date: date_param,
      })
          .then((response) => {
            const data = response.data[date_param];
            this.date = date;
            this.summary = data.summary;
            this.workingTimes = data.working_times;
            let timeTable = data.time_table
            timeTable = this.countTableMergeNo(timeTable)
            timeTable = this.setOverlapLevel(timeTable)
            timeTable = this.expandNearMergeTable(timeTable)
            this.timeTable = timeTable;
            this.loading = false;

            if ('URLSearchParams' in window) {
              const url = new URL(location.href);
              const todayDate = moment().format("YYYY-MM-DD")
              if (date_param === todayDate) {
                url.searchParams.delete('date');
              } else {
                url.searchParams.set('date', date_param);
              }
              history.replaceState(null, '', url);
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    //
    // テーブル結合のNoを計算する
    //
    countTableMergeNo(timeTable) {
      const mergeIds = []
      timeTable.forEach((t, i) => {
        t.reservations.forEach((r, j) => {
          if (r.is_table_merged) {
            if (!mergeIds.includes(r.id)) mergeIds.push(r.id)
            r.mergeNo = mergeIds.indexOf(r.id) + 1
          }
        })
      })
      return timeTable
    },
    //
    // 予約が重なっている場合に、重なりレベルを計算する
    //
    setOverlapLevel(timeTable) {
      timeTable.forEach((t, i) => {
        const tableReservations = t.reservations
        let tableOverlapLevel = 0

        tableReservations.forEach((r, j) => {
          tableReservations.filter((r2, j2) => {
            if (r2.state == 'busy' || r.state == 'busy') return false;

            const overlap = j2 > j && r2.start < r.end && r2.end > r.start
            if (overlap) {
              this.findAllSameReservation(timeTable, r.id).forEach((r) => {
                r.overlap = true
              })
              this.findAllSameReservation(timeTable, r2.id).forEach((r) => {
                r.overlap = true
              })
              r.overlapLevel ||= 0
              r2.overlapLevel = r.overlapLevel + 1

              if (tableOverlapLevel < r2.overlapLevel) {
                tableOverlapLevel = r2.overlapLevel
              }
            }
            return overlap;
          })

          if (r.type === 'pos') {
            this.findAllSameReservation(timeTable, r.id).forEach((r3) => {
              r3.mergeable_reservations ||= []
              r3.mergeable_reservations = r3.mergeable_reservations.concat(tableReservations.filter((r2, j2) => {
                return j2 != j && r2.start < r.end && r2.end > r.start
                    && r2.type != 'pos'
                    && r2.state != 'busy'
                    && r2.pos_order_id == null
              }))
            })
          }
        })
        timeTable[i].table.overlapLevel = tableOverlapLevel
        timeTable[i].table.offsetY ||= i
        if (i + 1 < timeTable.length) {
          timeTable[i + 1].table.offsetY = timeTable[i].table.offsetY + 1 + tableOverlapLevel
        }
      })
      return timeTable
    },
    //
    // となりのテーブルと結合して予約された場合は、繋がっているような見せ方にする
    //
    expandNearMergeTable(timeTable) {
      timeTable.forEach((t, i) => {
        if (i < 1) return

        const prevRow = timeTable[i - 1].reservations
        const prevTable = timeTable[i - 1].table
        const thisRow = t.reservations
        const thisTable = t.table
        thisRow.forEach((r, j) => {
          const prevR = prevRow.find((r2) => r2.id === r.id)
          if (prevR) {
            prevR.hide = true
            r.expandLevel = (prevR.expandLevel || 0) + (prevTable.overlapLevel || 0) + 1
          }
        })
      })
      return timeTable
    },
    findAllSameReservation(timeTable, id) {
      const result = []
      timeTable.forEach((t, i) => {
        t.reservations.forEach((r, j) => {
          if (r.id === id) {
            result.push(r)
          }
        })
      })
      return result
    },
    async getNotices(date) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;

      await Http.get(`${slug}/shop_manager/shop_notices`, {
        date: moment(date).format("YYYY-MM-DD"),
        page_size: 1000,
        page: 1,
      })
          .then((response) => {
            this.notices = response.data.notices
          })
          .catch((error) => {
            console.log(error);
          });
    },
    async confirmNotice(notice) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;

      await Http.patch(`${slug}/shop_manager/shop_notices/` + notice.id + "/confirm")
          .then((response) => {
            if (notice.data?.redirect_path) {
              location.href = `${slug}${notice.data.redirect_path}`
              return
            }
            this.getNotices(this.date);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    async getMemo(date) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;

      await Http.get(`${slug}/shop_manager/shop_memos`, {
        date: moment(date).format("YYYY-MM-DD"),
      })
          .then((response) => {
            this.memoList = response.data.data
            this.memo = response.data.data.length > 0 ? response.data.data[0] : {}
          })
          .catch((error) => {
            console.log(error);
          });
    },
    async saveMemo(params) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;

      this.loading = true;
      await Http.post(`${slug}/shop_manager/shop_memos/`, params)
          .then((response) => {
            this.loading = false;
            this.getMemo(this.date);
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
    },

    mountPrintScreen() {
      const mountElement = document.querySelector("#reservationPrint");
      if (mountElement) {
        this.printComponentInstance = new Vue({
          render: (h) => h(PrintModalForm, {
            props: {
              printTimeStart: moment(this.workingTimes[0].start_time).format('HH:mm'),
              printTimeEnd: moment(this.workingTimes[0].end_time).format('HH:mm'),
            },
            on: {
              'active-print-mode': this.activePrintMode,
              'change-modal-reservation-course': this.changeModalReservationCourse,
              'change-modal-reservation': this.changeModalReservation,
              'active-reservation-list': this.activeReservationList,
            }
          })
        });
        this.printComponentInstance.$mount();
        mountElement.appendChild(this.printComponentInstance.$el);
      }
    },
    changeModalReservationCourse() {
      this.showModalReservationCourse = !this.showModalReservationCourse
    },
    changeModalReservation() {
      this.showReservationModal = !this.showReservationModal
    },
    changePrintDialog() {
      this.showPrintDialog = !this.showPrintDialog
    },
    unmountPrintScreen() {
      if (this.printComponentInstance.$el) {
        // Destroy the Vue instance
        this.printComponentInstance.$destroy();
        // Remove the mounted element from the DOM
        this.printComponentInstance.$el.remove();
      }
    },
    remountPrintComponent() {
      this.unmountPrintScreen()
      this.mountPrintScreen()
    },
    async activePrintMode(printStart, printEnd) {
      let start = moment(this.date).format('YYYY-MM-DD') + ' ' + printStart
      let end = moment(this.date).add(printEnd < printStart ? 1 : 0, 'days').format('YYYY-MM-DD') + ' ' + printEnd
      this.printError = null
      if (moment(start).isBefore(this.timeTableStart) || moment(end).isAfter(this.timeTableEnd)) {
        start = moment(start).add(1, 'days')
        end = moment(end).add(1, 'days')
      }

      this.loading = true
      document.body.classList.toggle('print-mode')
      this.printPressTime = moment().format("YYYY/MM/DD HH:mm")
      this.printTimeStart = start
      this.printTimeEnd = end
      await sleep(300)
      this.showPrintDialog = false
      this.loading = false
    },
    async closePrintMode() {
      this.loading = true
      document.body.classList.toggle('print-mode')
      this.printPressTime = null
      this.printTimeStart = null
      this.printTimeEnd = null
      this.showPrintDialog = false
      await sleep(300)
      this.loading = false
    },
    async activeReservationList(printStart, printEnd) {
      this.selectedDate = moment(this.date).format('YYYY-MM-DD')
      this.startTime = printStart
      this.endTime = printEnd
      this.displayReservationList = true
    },

    mountReservationTimeComponent() {
      const mountElement = document.querySelector("#reservationTime");

      if (mountElement) {
        this.reservationComponentInstance = new Vue({
          render: (h) => h(ReservationTime, {
            props: {
              workingTimes: this.workingTimes
            },
          })
        });

        this.reservationComponentInstance.$mount();
        mountElement.appendChild(this.reservationComponentInstance.$el);
      }
    },
    umountReservationTimeComponent() {
      if (this.reservationComponentInstance.$el) {
        // Destroy the Vue instance
        this.reservationComponentInstance.$destroy();
        // Remove the mounted element from the DOM
        this.reservationComponentInstance.$el.remove();
      }
    },
    remountReservationTimeComponent() {
      this.umountReservationTimeComponent()
      this.mountReservationTimeComponent()
    },
    mountReservationSummaryComponent() {
      const mountElement = document.querySelector("#toMountData");
      if (mountElement) {
        this.dateComponentInstance = new Vue({
          render: (h) => h(DateComponent, {
            props: {
              date: this.date,
              showStatus: this.showStatus,
              printPressTime: this.printPressTime,
              timeTableStart: this.timeTableStart,
              timeTableEnd: this.timeTableEnd,
              summary: this.summary,
              printError: this.printError
            },
            on: {
              'change-date': this.changeDate,
              'close-print-mode': this.closePrintMode
            }
          })
        });

        this.dateComponentInstance.$mount();
        mountElement.appendChild(this.dateComponentInstance.$el);
      }
    },
    unmountReservationSummaryComponent() {
      if (this.dateComponentInstance.$el) {
        this.dateComponentInstance.$destroy();
        this.dateComponentInstance.$el.remove();
      }
    },
    remountReservationSummaryComponent() {
      this.unmountReservationSummaryComponent()
      this.mountReservationSummaryComponent()
    }
  }
};
</script>