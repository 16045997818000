<template>
<div class="top-navi">
<div class="d-flex flex-row align-items-center " :class="printPressTime ? 'justify-content-between': '' ">
  <div class="d-flex flex-row align-items-center" :class="printPressTime ? 'disable-pointer-event' : '' ">
    <div class="m-0 btn-outline-grey btn-outline-grey-unfocus btn-main" style="font-size: 20px; min-width: 150px; height: 52px;">
      <button class="btn btn-icon m-0 ml-1 mr-1" @click="prevDay">
        <i class="fa fa-angle-left" style="font-size: 2rem;color:var(--color-text-reservation)" aria-hidden="true"></i>
      </button>
      <DatePicker
        :value="date"
        :clearable="false"
        @change="changeDate">
      <template v-slot:input>
        <div class="calender-class">
          <div class="circle" style="position: relative; "> <i class="fa fa-calendar-o" style="font-size:16px;position: relative;top:3px;left: 3px;"></i></div>
          <span style="cursor: pointer; top:5px;position: relative">{{displayDate}}</span>
        </div>

      </template>
        <i slot="icon-calendar"></i>
    </DatePicker>

      <button class="btn btn-icon m-0 ml-1 mr-1" @click="nextDay">
        <i class="fa fa-angle-right" style="font-size: 2rem;color:var(--color-text-reservation)" aria-hidden="true"></i>
      </button>

    </div>
    <button class="btn px-3 fs-20 border-0 ml-2 btn-today btn-main" :class="[isToday ? 'btn-grey btn-grey-unfocus' : 'btn-outline-grey btn-outline-grey-unfocus']" style="height:52px;" @click="toToday"><span ><i class="fa fa-refresh" aria-hidden="true"></i> 今日</span></button>

      <div class="px-3 ml-2 d-flex align-items-center summary-items btn-main">
        <span style="font-size: 14px; margin-right: 0.5rem">予約件数: </span><span>{{this.summary.group && this.summary.group }}<span style="font-size: 10px;">組</span><span style="margin-left: 0.5rem">{{ this.summary.people && this.summary.people}}<span style="font-size: 10px;">名</span></span></span>
        </div>
      </div>


  <div class="print-btn-group d-flex align-items-center" v-if="printPressTime">
    <div class="mx-2">{{ `印刷時間 ${printPressTime}` }}</div>
    <button class="btn btn-primary btn-primary-unfocus btn-print-done" @click="closePrintMode">印刷完了</button>
  </div>
</div>
  <div v-if="printError" class="pt-1 pl-2 error">{{ printError }}</div>
</div>
</template>
<script>
  import moment from "moment/moment";
  import DatePicker from 'vue2-datepicker';

  export default {
    name: 'DateComponent',
    props: {
      date:{
        Type: Date
      },
      printPressTime:{
        Type:Date,
      },
      showStatus:{
        Type: Boolean,
      },
      timeTableStart: {
        type: Object
      },
      timeTableEnd: {
        type: Object
      },
      summary: {
        type: Object
      },
      printError:{
        type: String
      }
    },

    components: {DatePicker},
    computed:{
      isToday() {
        return moment(this.date).isSame(moment(), 'day');
      },
      displayDate() {
        return moment(this.date).format('YYYY/MM/DD (ddd)')
      },

    },
    watch:{
      date(){
        console.log("data", this.date)
      },
    },
    methods: {
      changeDate(date) {
        this.$emit('change-date', date);
      },
      toToday() {
        this.$emit('change-date', new Date());
        this.$root.$emit('check-reservation', new Date());
      },
      prevDay() {
        const timestamp = new Date(this.date.getTime() - 24 * 60 * 60 * 1000)
        this.$emit('change-date', timestamp);
        this.$root.$emit('check-reservation', timestamp);
      },
      nextDay() {
        const timestamp = new Date(this.date.getTime() + 24 * 60 * 60 * 1000)
        this.$emit('change-date', timestamp);
        this.$root.$emit('check-reservation', timestamp);
      },
      closePrintMode(){
        this.$emit('close-print-mode');
      }
    }
  }

</script>
<style scoped>

.calender-class{
 padding-left: 8px;
  padding-right: 8px;
  margin-top: 6px;
  border-right: 1px solid #D9C6B2;
  border-left: 1px solid #D9C6B2;
}
.circle {
  display: inline-block;
  height: 32px;
  width: 32px;
  background-color: var(--color-background-secondary);
  border-radius: 50%;
  top:1px;
}
.mx-datepicker-popup {
  position: absolute;
  margin-top: 1px;
  margin-bottom: 1px;
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  z-index: 2001;
}
.mx-datepicker-main {
  font: 14px/1.5 'Helvetica Neue', Helvetica, Arial, 'Microsoft Yahei', sans-serif;
  color: #66615B;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.summary-items{
  padding:6px;
  border: 1px solid var(--color-text-reservation);
  font-size: 24px;
  color: var(--color-text-reservation);
  height: 54px;
}
.sub{
  font-size: 10px;
}
</style>