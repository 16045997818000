<template>
  <div class="reservation-list-full-screen" id="order-to-print" >
    <div class="d-flex justify-content-end align-items-center  above-print">

      <div class="d-flex hide-print" id="hide-print">
        <button v-if="timeTableWithReservations.length>0" class="print-button me-2 p-1 btn-primary rounded" @click="downloadPdf">
          &nbsp;<i class=" fa fa-lg fa-download" aria-hidden="true"></i><span> Download PDF&nbsp;</span>
        </button>
        <button class="close" @click="close">
          <i class="fa fa-close" aria-hidden="true"></i>
        </button>
      </div>


    </div>
    <table >
      <thead>
      <tr>
        <td colspan="5" class="content no-border"><span>{{formattedDate}} {{startTime}} - {{endTime}}</span></td>
      </tr>
      <tr>
        <th>時間</th>
        <th>お客様情報</th>
        <th>人数</th>
        <th>テーブル</th>
        <th>
          コース/補足</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="entry in timeTableWithReservations" :key="entry.reservation_id">
        <td>{{ formatTime(entry.start_time) }}</td>
        <td>
          {{ entry.last_name }} {{ entry.first_name }} 様 <span v-if="entry.customer.count_reservation">({{entry.customer.count_reservation}}) </span>
          <br/>
          <span>{{entry.customer.tel}}</span>
          <br/>
          <span>{{entry.customer.company_name}}</span>
        </td>
        <td>{{ entry.number_of_people }}名</td>
        <td>
          <span v-for="(table, index) in entry.table_name" :key="index">
              {{ table }}<span v-if="index < entry.table_name.length - 1">, </span>
          </span>
        </td>
        <td >
          <span class="d-flex flex-column">
          <span>{{entry.course_name}}</span>
          <span>{{entry.memo}}</span>
          <span>{{entry.memo_from_customer}}</span>
          <span v-if="entry.customer && entry.customer.properties && entry.customer.properties.length > 0">
            <span v-for="(property, index) in entry.customer.properties" :key="index">
                {{ property.name }}<span v-if="index < entry.customer.properties.length - 1">, </span>
            </span>
          </span>
          <span v-if="entry.tags.length > 0">
            <span v-for="(tag, index) in entry.tags" :key="index">
                {{ tag }}<span v-if="index < entry.tags.length - 1">, </span>
            </span>
          </span>
            </span>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import Http from "../../shared/http";
import moment from "moment";
import printJS from 'print-js';
export default {
  props: {
    selectedDate: {
      type: String,
      required: true,
    },
    startTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
    displayReservationList:{
      type:Boolean,
    }
  },
  data() {
    return {
      timeTableWithReservations: [],
      showReservationList: this.displayReservationList
    };
  },
  computed: {
    formattedDate() {
      return  moment(this.selectedDate).format('YYYY年M月D日 (ddd)')
    },
  },
  methods: {
    downloadPdf() {
      const pdfFileName = `${this.selectedDate}  ${this.startTime} - ${this.endTime}`;
      printJS({
        printable: 'order-to-print',
        type: 'html',
        style: `
     table {
     width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        box-sizing: border-box;
    }
    th, td {
        border: 1px solid #ddd;
        padding: 4px;
        text-align: left;
      }
     .no-border {
      border:none;
     }
     .flex-column{
      height: 100%;
      display: flex;
      flex-direction: column;
     }
     `,
        showModal: true,
        ignoreElements: ["hide-print"],
        targetStyle: ['padding'],
        documentTitle: pdfFileName
      });
    },
    close() {
      this.showReservationList = false;
      this.$emit('update:displayReservationList', false);
    },
    async getData() {
      try {
        const slugElement = document.getElementById("slug");
        const slug =
            slugElement && slugElement.value !== "" ? `/${slugElement.value}` : "";
        const response = await Http.get(`${slug}/shop_manager/time_table/time_table_pdf`, {
          params: {
            date: this.selectedDate,
            start_time: this.startTime,
            end_time: this.endTime,
          },
        });
        this.timeTableWithReservations = response.data.time_table.reservations
        // this.transformData(response.data.time_table);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    transformData(timeTable) {
      this.timeTableWithReservations = [];
      timeTable.forEach((table) => {
        table.reservations.forEach((reservation) => {
          this.timeTableWithReservations.push({
            table_name: table.table_name,
            reservation_id: reservation.reservation_id,
            start_time: reservation.start_time,
            number_of_people: reservation.number_of_people,
            reservations: [
              {
                last_name: reservation.last_name,
                first_name: reservation.first_name,
                memo: reservation.memo,
                memo_from_customer:reservation.memo_from_customer,
                course_name:reservation.course_name,
                tags:reservation.tags,
                customer: {
                  id: reservation.customer.id,
                  tel: reservation.customer.tel,
                  company_name:reservation.customer.company_name,
                  count_reservation: reservation.customer.count_reservation,
                  properties: reservation.customer.properties
                },
              },
            ],
          });
        });
      });
    },
    formatTime(dateString) {
      return moment(dateString).format("HH:mm");
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.reservation-list-full-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  overflow: auto;
  background-color: white;
  padding: 40px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th,
td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
  font-weight: bold;
}
tr:hover {
  background-color: #f1f1f1;
}
.hide-print{
  gap:20px
}
.content{
  margin-top: 20px;
  text-align: center;
}
</style>