<template>
  <div>
    <div class="modal fade show" style="display: block;">
      <div class="modal-dialog" >
        <div class="modal-content" >
          <div class="modal-header header_modal_icon" style="background-color: #0090D7;">
            <h5 class="modal-title " style="color: white;">流入経路と担当者確認</h5>
            <img :src="cross" @click="close" class="cross cursor-pointer" />
          </div>
          <div class="modal-body">



      <div class="reservation-type">

        <div class="reservation-method">
          <label>流入経路</label>
          <div  v-for="(item, index) in types" :key="index">
            <div  class="content-items" >
              <div  class="content-input">
                <input
                    v-model="activeReservation.data.reservation_type"
                    type="radio"
                    :value="item.value"
                    class="transform-checkbox"
                    :id="'item' + index"
                    :name="'item'"
                    @change="selectReservationType(item.value)"

                >
                <span class="label-title" :for="'course' + index">{{item.label}}</span>

              </div>

            </div>
          </div>

        </div>
        <div class="reservation-staff">
          <label>
            予約担当者
          </label>
          <div class="form-control" style="font-size: 20px;">

            {{activeReservation.data.staff}}


          </div>
          <div class="staff-list mt-2">



            <div v-for="item,index in staffs" class="staff-items" @click="selectStaff(item)" :class="isSelected(item.name) ? 'selected': ''">
              <div class="staffs">


              {{ item.name }}
              </div>


            </div>
          </div>

        </div>

      </div>

    </div>
    <div class="modal-footer">
      <button type="button" @click="submitReservation()" class="btn btn-primary pl-2 pr-2">完了する</button>

    </div>


  </div>
  </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>

</template>
<script>
import stores from "../stores";
import active_reservation from "../stores/modules/reservations/active_reservation";
import cross from "../../../../assets/images/white-cross.svg";
export default{
  computed:{
    activeReservation(){
      return stores.getters.getActiveReservation},
  },
  props:{
    staffs:{
      type: Array,
      default:[]
    }
  },
  data(){
    return{
      types:[{value:'phone',label:'電話予約'},{value:'admin',label:'管理者'}],
      cross:cross
    }
  },
  methods:{
    selectStaff(item){
      stores.commit('SET_DATA',{staff:item.name})

    },
    isSelected(item){
      return this.activeReservation.data.staff === item
    },
    selectReservationType(type){
      stores.commit('SET_DATA',{reservation_type:type})
    },
    submitReservation(){
      this.$emit('submit-reservation')
    },
    close(){
      stores.commit('SET_SHOW_RESERVATION_MODAL',false)
    },
  }
}

</script>

<style scoped>
.staff-list{
  border-radius: 5px;
  cursor: pointer;
  height:25dvh;
  border: 1px solid var(--color-text-reservation);
  overflow-y: scroll;
  .selected{
    background-color: var(--main_opacity20);
  }
}
.staffs{
  padding:5px;
}
.reservation-staff{
  width: 60%;
}
.reservation-method{
  width: 40%;
}
.reservation-staff{
  width: 50%;
}
.reservation-type{
  display:flex;
  height:100%;
  width:100%
}
.staff-items{
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 20px;
}


.modal-dialog {
  z-index: 1050;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  max-width:unset;
  width:50%;
}

@media (min-width: 768px) and (max-width: 834px) and (orientation: portrait),
(min-width: 1024px) and (max-width: 1194px) and (orientation: landscape),
(min-width: 810px) and (max-width: 1080px) and (orientation: portrait),
(min-width: 1180px) and (max-width: 1366px) and (orientation: landscape) {
  .modal-dialog {
    width: 80%;
  }
}
.message-wrapper{
  width: 100%;
  text-align: left;
  padding-top: 16px;
}
label{
  font-size: 16px;
  color:var(--color-text-reservation);
}

.cross {
  width: 20px;
  color: white;
  margin-left: auto;
}
.modal-header{
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  justify-content: center;
}
.modal-content{
  min-height: calc(100vh - 200px);
  border-radius: 12px;
}

@media only screen
and (min-device-width: 768px){
  .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
  }
  .modal-content{
    min-height: calc(100vh - 400px);
  }
  .error-message{
    font-size: 18px;
  }
}
.label-title{
  font-size:18px;

}
.content-input{
  display: flex;
  gap:10px;
  align-items: center;
}
.transform-checkbox {
  width: 18px;
  height: 18px;
  accent-color: var(--color-text-reservation);
}
.staff-list::-webkit-scrollbar {
  display: none;
}
</style>